import { useMemo } from 'react';

import { TokenType, useConfig } from '../contexts/ConfigContext';

function useNativeTokenConfig() {
  const { l1Tokens, l2Tokens } = useConfig();

  return useMemo(
    () => [...l1Tokens, ...l2Tokens].find(token => token.type === TokenType.NATIVE),
    [l1Tokens, l2Tokens]
  );
}

export default useNativeTokenConfig;
