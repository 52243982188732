import { useMemo } from 'react';

import { useConfig } from '../contexts/ConfigContext';
import { Side } from './../core/type';

function useToken(side: Side, address?: string) {
  const { l1Tokens, l2Tokens } = useConfig();

  return useMemo(
    () =>
      (side === Side.l1 ? l1Tokens : l2Tokens).find(
        token => token.address.toLowerCase() === address?.toLowerCase()
      ),
    [address, l1Tokens, l2Tokens, side]
  );
}

export default useToken;
