import { BigNumber, Contract } from 'ethers';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAccount } from 'wagmi';

import { ERC20BalanceOf } from '../core/abis';
import { Side } from '../core/type';
import { formatDisplayUnits } from '../core/utils';
import { useStaticProvider } from '../hooks/useStaticProvider';
import { BridgeToken, TokenType, useConfig } from './ConfigContext';

type Address = string;
export type TokenKey = `l1${Address}` | `l2${Address}`;

interface Context {
  tokenBalances: Record<TokenKey, BigNumber>;
  fetchBalance: () => Promise<void>;
}

export const TokenBalanceContext = createContext<Context>({} as Context);

export const useTokenBalances = () => {
  return useContext(TokenBalanceContext);
};

export const TokenBalanceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const account = useAccount();
  const { l1Tokens, l2Tokens } = useConfig();
  const l1Provider = useStaticProvider('l1');
  const l2Provider = useStaticProvider('l2');
  const [tokenBalances, setTokenBalances] = useState<Record<string, BigNumber>>({});

  const fetchBalance = useCallback(async () => {
    if (!account.isConnected || !account.address) {
      return;
    }

    const balances = await Promise.all(
      [...l1Tokens, ...l2Tokens].map(async token => {
        const address = account.address as string;
        const provider = token.side === Side.l1 ? l1Provider : l2Provider;

        try {
          if (token.type === TokenType.NATIVE) {
            return await provider.getBalance(address);
          }

          const contract = new Contract(token.address, [ERC20BalanceOf], provider);

          return contract
            .balanceOf(address)
            .catch((err: Error) =>
              console.error('Error getting balance: ', err)
            ) as Promise<BigNumber>;
        } catch (e) {
          return BigNumber.from(0);
        }
      })
    );

    const tokenBalances = balances.reduce((all: Record<TokenKey, BigNumber>, balance, index) => {
      if (index < l1Tokens.length) {
        all[`${Side.l1}${l1Tokens[index].address}`] = balance;
      } else {
        all[`${Side.l2}${l2Tokens[index - l1Tokens.length].address}`] = balance;
      }

      return all;
    }, {} as Record<TokenKey, BigNumber>);

    setTokenBalances(tokenBalances);
  }, [account.address, account.isConnected, l1Provider, l1Tokens, l2Provider, l2Tokens]);

  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  return (
    <TokenBalanceContext.Provider value={{ tokenBalances, fetchBalance }}>
      {children}
    </TokenBalanceContext.Provider>
  );
};

export const useTokenBalance = (
  token: BridgeToken | null,
  side: Side
): [BigNumber | undefined, string] => {
  const { tokenBalances } = useTokenBalances();
  const balance = token ? tokenBalances[`${side}${token.address}`] : undefined;

  return useMemo(
    () => [balance, balance && token ? formatDisplayUnits(balance, token.decimals, 3) : '0'],
    [balance, token]
  );
};
