import { Box, CssBaseline, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { createContext, useContext, useEffect, useState } from 'react';
import { Chain } from 'wagmi';

import IconLoadCircle from '../components/icons/IconLoadCircle';
import IconNotFound from '../components/icons/IconNotFound';
import { BaseLayout } from '../components/Layout';
import { Side } from '../core/type';
import { useCustomSearchParams } from '../hooks/useCustomSearchParams';
import { inputGlobalStyles } from '../theme/globalStyle';

export interface NetWorkConfig extends Chain {
  bridgeAddress: string;
  erc721GraphQlUrl: string;
}

export enum TokenType {
  ERC20 = 'erc20',
  XERC20 = 'xerc20',
  ERC721 = 'erc721',
  NATIVE = 'native',
}

export class BridgeToken {
  side!: Side;
  address!: string;
  name!: string;
  symbol!: string;
  decimals!: number;
  type!: TokenType;
  oppositeToken!: BridgeToken;
}

interface Context {
  l1: NetWorkConfig;
  l2: NetWorkConfig;
  l1Tokens: BridgeToken[];
  l2Tokens: BridgeToken[];
  nativeTokenMapping?: {
    l1Erc20MappedToL2Native: string;
    l2Erc20MappedToL1Native: string;
  };
}

interface NetworkFromBackend {
  chainId: string;
  endpoints: string[];
  nativeToken: {
    name: string;
    symbol: string;
    decimals: string;
  };
  chainName: string;
  explorer: string;
}

export const ConfigContext = createContext<Context>({} as Context);

export async function fetchConfig(configUrl: string): Promise<{
  l1: NetworkFromBackend;
  l2: NetworkFromBackend;
  contract: {
    l1: string;
    l2: string;
  };
  tokens: {
    l1Address: string;
    l2Address: string;
    tokenType: string;
    tokenMetadata: {
      name: string;
      symbol: string;
      decimals: string;
    };
  }[];
  nativeTokenMapping?: {
    l1Erc20MappedToL2Native: string;
    l1Erc20MappedToL2NativeName: string;
    l1Erc20MappedToL2NativeSymbol: string;
    l2Erc20MappedToL1Native: string;
    l2Erc20MappedToL1NativeName: string;
    l2Erc20MappedToL1NativeSymbol: string;
  };
}> {
  const res = await fetch(configUrl || '');
  const body = await res.json();

  return body;
}

export const useConfig = () => {
  return useContext(ConfigContext);
};

const appConfig = window.appConfig;

const PlaceholderForConfig: React.FC<{ loading: boolean }> = ({ loading }) => (
  <BaseLayout>
    <CssBaseline />
    {inputGlobalStyles}
    <Dialog open={true}>
      <DialogTitle>
        {loading ? 'Loading network configuration' : 'Unable to find network'}
      </DialogTitle>
      <DialogContent sx={{ width: '500px' }}>
        <Box
          alignItems="center"
          display="flex"
          height="150px"
          justifyContent="center"
          mb={2}
          width="100%"
        >
          {loading ? (
            <IconLoadCircle
              sx={{
                width: '80px',
                height: '80px',
                animation: 'rotation 1.5s infinite linear',
              }}
            />
          ) : (
            <IconNotFound
              sx={{
                width: '80px',
                height: '80px',
              }}
            />
          )}
        </Box>
        <Typography textAlign="center">
          {loading
            ? 'If loading take a long time, you may have provided an invalid url'
            : 'Please check that you have provided the correct network ID'}
        </Typography>
      </DialogContent>
    </Dialog>
  </BaseLayout>
);

export const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [{ l1, l2 }, setNetwork] = useState<{ l1: NetWorkConfig | null; l2: NetWorkConfig | null }>(
    {
      l1: null,
      l2: null,
    }
  );
  const [{ l1Tokens, l2Tokens, nativeTokenMapping }, setTokens] = useState<{
    l1Tokens: BridgeToken[];
    l2Tokens: BridgeToken[];
    nativeTokenMapping?: {
      l1Erc20MappedToL2Native: string;
      l2Erc20MappedToL1Native: string;
    };
  }>({
    l1Tokens: [],
    l2Tokens: [],
  });
  const [loading, setLoading] = useState(false);
  const {
    searchParms: { id },
  } = useCustomSearchParams<{ id?: string }>();

  useEffect(() => {
    if (!id) {
      const l1NativeMeta = appConfig?.bridge?.l1?.nativeCurrency || {
        decimals: 18,
        name: 'Ethereum',
        symbol: 'ETH',
      };
      const l2NativeMeta = appConfig?.bridge?.l2?.nativeCurrency || {
        decimals: 18,
        name: 'Ethereum',
        symbol: 'ETH',
      };
      const l1NativeToken: BridgeToken = Object.assign(new BridgeToken(), {
        address: appConfig?.nativeTokenL2Address,
        name: l1NativeMeta.name,
        symbol: l1NativeMeta.symbol,
        decimals: l1NativeMeta.decimals,
        type: TokenType.NATIVE,
        side: Side.l1,
      });
      const l2NativeToken: BridgeToken = Object.assign(new BridgeToken(), {
        address: appConfig?.nativeTokenL2Address,
        name: l2NativeMeta.name,
        symbol: l2NativeMeta.symbol,
        decimals: l2NativeMeta.decimals,
        type: TokenType.NATIVE,
        side: Side.l2,
      });
      const tokens = appConfig?.bridge?.tokens || [];
      const l1Tokens = tokens.map(token =>
        Object.assign(new BridgeToken(), token, {
          side: Side.l1,
          address: token.l1Address,
        })
      );
      const l2Tokens = tokens.map(token =>
        Object.assign(new BridgeToken(), token, {
          side: Side.l2,
          address: token.l2Address,
        })
      );

      l1NativeToken.oppositeToken = l2NativeToken;
      l2NativeToken.oppositeToken = l1NativeToken;
      l1Tokens.forEach((token, i) => {
        token.oppositeToken = l2Tokens[i];
        l2Tokens[i].oppositeToken = token;
      });

      setNetwork({
        l1: appConfig?.bridge?.l1 || null,
        l2: appConfig?.bridge?.l2 || null,
      });
      setTokens({
        l1Tokens: [l1NativeToken, ...l1Tokens],
        l2Tokens: [l2NativeToken, ...l2Tokens],
      });

      return;
    }

    if (!appConfig.configBaseUrl) {
      return;
    }

    const configUrl = `${appConfig.configBaseUrl}${
      appConfig.configBaseUrl?.endsWith('/') ? '' : '/'
    }${id}`;

    setLoading(true);
    fetchConfig(configUrl)
      .then(body => {
        const l1NativeMeta = body.l1.nativeToken || {
          decimals: 18,
          name: 'Ethereum',
          symbol: 'ETH',
        };
        const l2NativeMeta = body.l2.nativeToken || {
          decimals: 18,
          name: 'Ethereum',
          symbol: 'ETH',
        };
        const l1NativeToken = Object.assign(new BridgeToken(), {
          address: appConfig.nativeTokenL2Address,
          name: l1NativeMeta.name,
          symbol: l1NativeMeta.symbol,
          decimals: parseInt(l1NativeMeta.decimals),
          type: TokenType.NATIVE,
          side: Side.l1,
        });
        const l2NativeToken = Object.assign(new BridgeToken(), {
          address: appConfig.nativeTokenL2Address,
          name: l2NativeMeta.name,
          symbol: l2NativeMeta.symbol,
          decimals: parseInt(l2NativeMeta.decimals),
          type: TokenType.NATIVE,
          side: Side.l2,
        });
        const l1Tokens: BridgeToken[] = body.tokens.map(token =>
          Object.assign(new BridgeToken(), {
            address: token.l1Address,
            name: token.tokenMetadata.name,
            symbol: token.tokenMetadata.symbol,
            decimals: parseInt(token.tokenMetadata.decimals),
            type: token.tokenType as TokenType,
            side: Side.l1,
          })
        );
        const l2Tokens: BridgeToken[] = body.tokens.map(token =>
          Object.assign(new BridgeToken(), {
            address: token.l2Address,
            name: token.tokenMetadata.name,
            symbol: token.tokenMetadata.symbol,
            decimals: parseInt(token.tokenMetadata.decimals),
            type: token.tokenType as TokenType,
            side: Side.l2,
          })
        );

        l1Tokens.forEach((token, i) => {
          token.oppositeToken = l2Tokens[i];
          l2Tokens[i].oppositeToken = token;
        });

        if (body.nativeTokenMapping) {
          const l1Erc20: BridgeToken = Object.assign(new BridgeToken(), {
            address: body.nativeTokenMapping.l1Erc20MappedToL2Native,
            name: body.nativeTokenMapping.l1Erc20MappedToL2NativeName,
            symbol: body.nativeTokenMapping.l1Erc20MappedToL2NativeSymbol,
            decimals: 18,
            type: TokenType.ERC20,
            side: Side.l1,
          });
          const l2Erc20: BridgeToken = Object.assign(new BridgeToken(), {
            address: body.nativeTokenMapping.l2Erc20MappedToL1Native,
            name: body.nativeTokenMapping.l2Erc20MappedToL1NativeName,
            symbol: body.nativeTokenMapping.l2Erc20MappedToL1NativeSymbol,
            decimals: 18,
            type: TokenType.ERC20,
            side: Side.l2,
          });

          l1Erc20.oppositeToken = l2NativeToken;
          l2Erc20.oppositeToken = l1NativeToken;
          l1NativeToken.oppositeToken = l2Erc20;
          l2NativeToken.oppositeToken = l1Erc20;
          l1Tokens.unshift(l1Erc20);
          l2Tokens.unshift(l2Erc20);
        } else {
          l1NativeToken.oppositeToken = l2NativeToken;
          l2NativeToken.oppositeToken = l1NativeToken;
        }

        l1Tokens.unshift(l1NativeToken);
        l2Tokens.unshift(l2NativeToken);
        setNetwork({
          l1: {
            id: parseInt(body.l1.chainId),
            name: body.l1.chainName,
            network: 'l1',
            nativeCurrency: {
              decimals: parseInt(body.l1.nativeToken.decimals),
              name: body.l1.nativeToken.name,
              symbol: body.l1.nativeToken.symbol,
            },
            bridgeAddress: body.contract.l1,
            erc721GraphQlUrl: '',
            rpcUrls: {
              public: { http: body.l1.endpoints },
              default: { http: body.l1.endpoints },
            },
            blockExplorers: body.l1.explorer
              ? {
                  default: {
                    name: 'explorer',
                    url: body.l1.explorer,
                  },
                }
              : undefined,
          },
          l2: {
            id: parseInt(body.l2.chainId),
            name: body.l2.chainName,
            network: 'l2',
            nativeCurrency: {
              decimals: parseInt(body.l2.nativeToken.decimals),
              name: body.l2.nativeToken.name,
              symbol: body.l2.nativeToken.symbol,
            },
            bridgeAddress: body.contract.l2,
            erc721GraphQlUrl: '',
            rpcUrls: {
              public: { http: body.l2.endpoints },
              default: { http: body.l2.endpoints },
            },
            blockExplorers: body.l2.explorer
              ? {
                  default: {
                    name: 'explorer',
                    url: body.l2.explorer,
                  },
                }
              : undefined,
          },
        });
        setTokens({
          l1Tokens,
          l2Tokens,
          nativeTokenMapping: body.nativeTokenMapping,
        });
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (!l1 || !l2) {
    return <PlaceholderForConfig loading={loading} />;
  }

  return (
    <ConfigContext.Provider value={{ l1, l2, l1Tokens, l2Tokens, nativeTokenMapping }}>
      {children}
    </ConfigContext.Provider>
  );
};
